/**
 * import and init global mixins
 */
// import Vue from "vue";
// // import currentUser from "../mixins/currentUser";
// // import jumpTo from "../mixins/jumpTo";
// // import formatDateTime from "../mixins/formatDateTime";
// // import PageLoader from "@/components/misc/PageLoader";
// import { DateTime } from "luxon";
// import numeral from "numeral";
// // import FooterMenu from "@/views/layout/FooterMenu";
// import { FileuploadService } from "@/services/fileupload.service";
// import _ from "lodash";
// import { useAuthStore } from "@/store/auth";

// // Vue.mixin(currentUser);
// // Vue.mixin(jumpTo);
// // Vue.mixin(formatDateTime);

// Vue.mixin({
//   methods: {
//     isoToLocale(dateStr) {
//       // console.log("Converting " + dateStr  + " to " + DateTime.fromISO(dateStr).toLocaleString(DateTime.DATETIME_MED));
//       return DateTime.fromISO(dateStr).toLocaleString(DateTime.DATETIME_MED);
//     },
//     formatAmount(amount) {
//       return numeral(amount).format("0,0.00[00]");
//     },
//     imageUrl(fileId) {
//       if (fileId == null) {
//         return "/images/seller-default-logo.png";
//       } else if (_.startsWith(fileId, "http")) {
//         return fileId;
//       } else if (_.startsWith(fileId, "category")) {
//         return FileuploadService.getPageImageUrl(fileId);
//       } else {
//         return FileuploadService.getFileUrl(fileId);
//       }
//     },
//   },
// });

// // global components

// Vue.component('page-loader', PageLoader)
// Vue.component('footer-menu', FooterMenu)
/* eslint-disable */ 
import { DateTime } from "luxon";
import numeral from "numeral";
import _ from "lodash";
import { FileuploadService } from "@/services/fileupload.service";

// Export the mixin
export default {
  methods: {
    isoToLocale(dateStr) {
      return DateTime.fromISO(dateStr).toLocaleString(DateTime.DATETIME_MED);
    },
    formatAmount(amount) {
      return numeral(amount).format("0,0.00[00]");
    },
    imageUrl(fileId) {
      if (fileId == null) {
        return "/images/seller-default-logo.png";
      } else if (_.startsWith(fileId, "http")) {
        return fileId;
      } else if (_.startsWith(fileId, "category")) {
        return FileuploadService.getPageImageUrl(fileId);
      } else {
        return FileuploadService.getFileUrl(fileId);
      }
    },
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return new Date(dateString).toLocaleDateString("en-UK", options);
    },
    formatTime(timeString) {
      const [hours, minutes] = timeString.split(":");
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Convert to 12-hour format
      return `${formattedHours}:${period}`;
    },
  },
};
