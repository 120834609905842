<template>
  <b-navbar class="findhub_navbar has-background-white">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="/logos/Logo_Name-2@2x.png"
          alt="Lightweight UI components for Vue.js based on Bulma"
        />
      </b-navbar-item>
    </template>
    <!-- <template #start>
      <b-navbar-item href="#"> Home </b-navbar-item>
      <b-navbar-item href="#"> Documentation </b-navbar-item>
    </template> -->
    <template #end>
      <a class="navbar-item">
        <router-link to="/about" class="has-text-black-ter fh-navbar-link"
          >About</router-link
        >
      </a>
      <a class="navbar-item">
        <router-link to="/" class="has-text-black-ter fh-navbar-link"
          >Store</router-link
        >
      </a>
      <a class="navbar-item">
        <router-link
          to="/join-as-seller"
          class="has-text-black-ter fh-navbar-link"
          >Join As Seller</router-link
        >
      </a>
      <a class="navbar-item">
        <router-link
          to="/seller-directories"
          class="has-text-black-ter fh-navbar-link"
          >Seller Directory</router-link
        >
      </a>
      <a class="navbar-item">
        <router-link to="/contact-us" class="has-text-black-ter fh-navbar-link"
          >Contact Us</router-link
        >
      </a>
      <a class="navbar-item">
        <b-dropdown aria-role="list" class="is-right">
          <template #trigger="{}">
            <button class="fh_navbar_dropdown_btn">
              <span class="mdi mdi-bell-outline"></span>
            </button>
          </template>
          <!-- <div class="dropdown-content"></div> -->
          <!-- <div
            class="dropdown-item"
            v-for="item in cartItems"
            :key="item.search_term_response_id"
          >
            <div class="fh-navbar-notification_list">
              <p>{{ item.quantity }} x</p>
              <p>{{ item.name }}</p>
              <strong>{{ item.total_price }}</strong>
            </div>
            <b-button type="is-danger" size="is-small" icon-right="delete" />
            <hr class="dropdown-divider" />
          </div> -->
        </b-dropdown>
      </a>

      <b-navbar-item tag="div">
        <div class="buttons">
          <!-- <a
            class="button is-primary"
            v-if="!authStore.user"
            @click="showLoginModal"
          >
            Log in
          </a> -->
          <b-button
            v-if="!authStore.user"
            @click="cardModal"
            label="Login"
            type="is-primary"
          />

          <!-- <a v-else class="button is-light" @click="logout"> Log out </a> -->
          <b-dropdown
            v-else
            v-model="isComponentModalActive"
            position="is-bottom-left"
            append-to-body
            aria-role="menu"
            class="is-hidden-mobile"
          >
            <template #trigger>
              <a class="navbar-item is-primary" role="button">
                <figure class="image">
                  <div class="user-initials">
                    {{ userInitials() }}
                  </div>
                </figure>
              </a>
            </template>

            <b-dropdown-item custom aria-role="menuitem">
              Logged as <b>{{ authStore.user.name }}</b>
            </b-dropdown-item>
            <hr class="dropdown-divider" />
            <b-dropdown-item
              value="home"
              aria-role="menuitem"
              class="is-flex is-align-items-center"
            >
              <b-icon icon="home" class="mr-2"></b-icon>
              <router-link to="/">Home</router-link>
            </b-dropdown-item>
            <b-dropdown-item
              value="products"
              aria-role="menuitem"
              class="is-flex is-align-items-center"
              @click="proceedToCheckout"
            >
              <b-icon pack="mdi" icon="cart" class="mr-2"></b-icon>
              Cart
              <span class="cart-ribbon">{{ cartItemCount }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              value="products"
              aria-role="menuitem"
              class="is-flex is-align-items-center"
            >
              <b-icon pack="mdi" icon="mdi mdi-truck" class="mr-2"></b-icon>
              Orders
            </b-dropdown-item>
            <hr class="dropdown-divider" aria-role="menuitem" />
            <b-dropdown-item
              value="settings"
              class="is-flex is-align-items-center"
            >
              <b-icon pack="mdi" icon="cog" class="mr-2"></b-icon>
              <router-link :to="`/${authStore.user.id}/profile`"
                >Profile</router-link
              >
            </b-dropdown-item>
            <b-dropdown-item
              value="logout"
              aria-role="menuitem"
              class="is-flex is-align-items-center"
              @click="logout"
            >
              <b-icon icon="logout" class="mr-2"></b-icon>
              Logout
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </b-navbar-item>
      <!-- <LoginModal v-model="isComponentModalActive" /> -->
      <!-- Bind modal visibility -->
    </template>
  </b-navbar>
</template>

<script>
/* eslint-disable */ 
//import { useRouter } from 'vue-router'
import { useAuthStore , useCartStore } from "@/store";
import { useRouter } from 'vue-router';
// import SampleDialog from './SampleDialog.vue'
// import VOtpInput from "vue3-otp-input";
import * as hellojs from "hellojs";
// import LoginModal from "./LoginModaBKPl.vue";
import LoginModal from "./LoginModal.vue";

export default {
  components: {
    LoginModal,
  },
  data() {
    return {
      isComponentModalActive: false,
      isLoginModalActive: false,
      activeTab: 0,
      mobile_number: "",
      otp: "",
      isOtpSent: false,
      isDisabled: false,
      requestId: null,
      loading: false,
      errorMessage: "",
      token: "",
      OTPisLoading: false,
      form: {
        email: "",
        password: "",
        device_name: "Web",
      },
      cartItemCount: 0,
      cartItems: [],
      showCartDropdown: false,
      cartStore :useCartStore()
    };
  },
  computed: {
    authStore() {
      return useAuthStore();
    },
    hasCartItems() {
      return this.cartItems && this.cartItems.length > 0; // Check if cartItems is defined and has items
    },
  },
  methods: {
    logout() {
      this.authStore.logout();
      this.$router.push("/");
    },
    async handleInitialAuth() {
      this.OTPisLoading = true;
      try {
        const response = await this.authStore.initiateOtpAuth(
          this.mobile_number
        );
        this.isOtpSent = true;
        this.requestId = response; // Assuming response has requestId
      } catch (error) {
        this.errorMessage = "Failed to send OTP. Please try again.";
      } finally {
        this.OTPisLoading = false;
      }
    },
    async handleOtpValidation() {
      this.isDisabled = true;
      this.loading = true;
      try {
        const isVerified = await this.authStore.validateOtp(
          this.requestId,
          this.otp
        );
        if (isVerified) {
          this.isComponentModalActive = false;
          this.$router.push("/");
        } else {
          this.errorMessage = "OTP verification failed.";
          this.isDisabled = false;
        }
      } catch (error) {
        this.errorMessage = "Failed to validate OTP. Please try again.";
        this.isDisabled = false;
      } finally {
        this.loading = false;
      }
    },
    async handleEmailLogin() {
      this.loading = true;
      try {
        const response = await this.authStore.login(this.form);
        if (response.policy_not_accepted) {
          console.log("response", response);
          console.log("policy_not_accepted");
          this.policyAcceptance(response.token);
        } else {
          console.log("policy_accepted");
          this.isComponentModalActive = false;
          this.$router.push("/");
        }
      } catch (error) {
        this.errorMessage = "Login failed. Please try again.";
      } finally {
        this.loading = false;
      }
    },
    policyAcceptance(token) {
      this.$buefy.dialog.confirm({
        title: "Findhub Customer Policy",
        message: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Fusce id fermentum quam. Proin sagittis,
                        nibh id hendrerit imperdiet, elit sapien laoreet elit,
                        ac scelerisque diam velit in nisl. Nunc maximus ex non
                        laoreet semper. Nunc scelerisque, libero sit amet pretium dignissim,
                        augue purus placerat justo,
                        sit amet porttitor dui metus in nisl.
                        Nulla non leo placerat, porta metus eu, laoreet risus.
                        Etiam lacinia, purus eu luctus maximus, elit ex viverra tellus,
                        sit amet sodales quam dui nec odio.
                        Nullam porta mollis est. Quisque aliquet malesuada fringilla.
                        Pellentesque volutpat lacus at ante posuere,
                        non pulvinar ante porta. Proin viverra eu massa nec porta.
                        Aliquam rhoncus velit quis sem hendrerit,
                        ut dictum nisl accumsan. Maecenas erat enim, scelerisque non ligula ac,
                        eleifend venenatis ligula.
                        Praesent molestie mauris sed elit posuere, non malesuada libero gravida.
                        In hac habitasse platea dictumst.
                        Pellentesque habitant morbi tristique senectus
                        et netus et malesuada fames ac turpis egestas.`,
        cancelText: "Disagree",
        confirmText: "Agree",
        type: "is-success",
        onConfirm: async () => {
          const response = await this.authStore.acceptPolicy(
            this.form.email,
            token
          );
          console.log("response from NAV 274", response);
          if (response.status === 200) {
            this.$buefy.toast.open(response.data.message);
            this.isComponentModalActive = false;
          }
        },
      });
    },
    cardModal() {
      this.$buefy.modal.open({
        parent: this,
        component: LoginModal,
        hasModalCard: true,
        customClass: "custom-class custom-class-2",
        trapFocus: true,
      });
    },
    toggleCartDropdown() {
      this.showCartDropdown = !this.showCartDropdown;
    },
    proceedToCheckout() {
      this.$router.push('/checkout');
    },
    userInitials() {
      if (this.authStore.user && this.authStore.user.name) {
        const names = this.authStore.user.name.split(" ").slice(0, 2); // Take only the first two names
        const initials = names.map((name) => name.charAt(0)).join("");
        return initials.toUpperCase();
      }
      return "";
    },
  },
  mounted() {
    hellojs.init({
      google: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      redirect_uri: "/oauth-redirect",
    });
    this.cartItems = this.cartStore.cart.cart_items;
  },
  watch: {
    "cartStore.cart": {
      handler(newValue) {
        this.cartItems = newValue.cart_items; // Update this.cart when cartStore.cart changes
        console.log(this.cartItems); // Log the updated cart
        this.cartItemCount = this.cartItems ? this.cartItems.length : 0; // Update this.cartItemCount when cartStore.cart changes
      },
      immediate: true, // Run the handler immediately on component mount
    },
  },
};
</script>

<style scoped >
 
.otp-input {
  display: flex;
    flex-direction: column;
    height: 100vh;
    box-sizing: border-box;
    background-size: cover;
}

.otp-input.is-complete {
  background-color: #e4e4e4;
}

.otp-input input[disabled] {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.navbar-item img {
  max-height: 3.5rem;
}

.findhub_navbar {
  border-radius: .2rem .2rem 0.8rem .8rem;
  background-color: transparent !important;;
}

.user-initials {
  font-size: 1.25rem; /* Adjust size as needed */
  font-weight: bold;

  text-align: center;
  width: 50px; /* Set width for alignment */
  height: 50px; /* Set height for alignment */
  border-radius: 50%; /* Make it circular */

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00a991;
    /* Change to your desired color */
    color: #ffffff;
    /* Text color */
}

.fh-navbar-link {
  position: relative; /* Ensure positioning for the pseudo-element */
}

.fh-navbar-link:hover {
  text-decoration: none; /* Remove default underline on hover */
}

/* Customize the underline */
.fh-navbar-link::after {
  content: '';
  display: block;
  height: 2px; /* Adjust thickness */
  background: rgb(83, 83, 83); /* Set underline color to white */
  position: absolute;
  bottom: 0; /* Position it at the bottom */
  left: 0;
  transform: scaleX(0); /* Initially hide the underline */
  transition: transform 0.3s ease; /* Smooth transition */
}

.fh-navbar-link:hover::after {
  transform: scaleX(1); /* Show underline on hover */
}

.custom-dropdown-width {
  max-width: 18rem;
  /* Adjust to your desired width */
}

.fh-navbar-notification_list{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cart-ribbon {
  background-color: red; /* Change color as needed */
  color: white; /* Text color */
  border-radius: 20px; /* Rounded corners */
  padding: 2px; /* Padding for the ribbon */
  margin-left: 5px; /* Space between the text and the ribbon */
  font-size: 0.7rem; /* Smaller font size */
  width:22px;
  height: 22px; /* Smaller ribbon size */
  text-align: center; /* Center align the text */
  position: relative; /* Ensure positioning for the pseudo-element */
}
.fh_navbar_dropdown_btn{
  width:2rem;
  height:2rem;
  background: transparent; /* Ensure background is transparent */
  border: none; /* Remove border */
  border-radius: 0.5rem; /* Add border radius for rounded corners */
  box-shadow: inset 0 0 15px rgba(133, 133, 133, 0.27), 0 1px 3px rgba(151, 151, 151, 0.3); /* Inner shadow and drop shadow */
}

.fh_navbar_dropdown_btn:focus,
.fh_navbar_dropdown_btn:active {
  background: transparent; /* Ensure background remains transparent */
  border: none; /* Ensure no border */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.7), 0 2px 5px rgba(0, 0, 0, 0.5); /* Darker inner shadow and drop shadow on focus/active */
}

</style>
