<template>
  <div class="findhub_spa-bg has-background-light"></div>
  <div class="app-layout findhub_spa">
    <main class="app-main">
      <div class="fh_main_info-bar has-background-white has-text-centered">
        <strong class="is-primary is-light">
          !Pre-release Testing
          <b-tooltip
            type="is-dark"
            label="Enter the official name of your business"
          >
            <b-icon size="is-small" icon="help-circle-outline"></b-icon>
          </b-tooltip>
        </strong>
      </div>
      <router-view />
    </main>
    <footer class="app-footer">
      <FooterHome />
    </footer>
  </div>
</template>

<script>
/* eslint-disable */
import { useAuthStore } from "./store/auth";
import Nav from "./components/Nav.vue";
import FooterHome from "./views/layout/FooterHome.vue";
import { messaging } from "@/services/firebaseService"; // Import your Firebase messaging
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import io from 'socket.io-client';


// window.Pusher = require('pusher-js');
// Pusher.logToConsole = true; // Enable Pusher debug logging

// // Initialize Laravel Echo globally with Pusher
// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: '0831ba376a02083bbb94',
//   cluster: 'ap2',
//   forceTLS: true
// });

export default {
  name: "App",
  components: {
    Nav,
    FooterHome,
  },
  data() {
    return {
      searchterm_id: 1, // Replace with the actual search term ID you want to subscribe to
      updateData: null,
    };
  },
  created() {
    // this.initializeWebSocket();
  },
  // beforeUnmount() {
  //   if (this.socket) {
  //     this.socket.disconnect();
  //   }
  // },
  // setup() {
  //   const authStore = useAuthStore();

  //   // Check the user's authentication status when the page is loaded
  //   authStore.checkAuthStatus();

  //   return {
  //     user: authStore.user,
  //   };
  // },
  mounted() {
    const authStore = useAuthStore();

    // Ensure the user ID is up to date
    const userId = authStore.user?.id || 1; // Use logged-in user ID or default to 1 for testing

    // Log the current authentication state
    messaging.onMessage((payload) => {
      console.log("Message received in the foreground: ", payload);
      const notificationTitle = payload.notification?.title;
      const notificationBody = payload.notification?.body;
      console.log("Notification Body:", notificationBody); // Log the entire body

      // Extract the ID from the notification body and log it
      const id = notificationBody; // Assuming the body contains the ID directly
      console.log("Extracted ID from notification body:", id);

      // Show toast notification
      this.$buefy.toast.open(`${notificationTitle}: ${notificationBody}`);
    });

    // this.initializeWebSocket();
    // Laravel Echo: Listen to Public and Private Channels

    // Listen for the public event
    // Listen to the public channel
    // window.Echo.channel('public-channel')
    //   .listen('.TestPublicEvent', (data) => {
    //     console.log('Received public event data:', data);
    //     this.$buefy.toast.open(`Public Channel: ${data.message}`);
    //   });

    // // Listen to the private channel for the authenticated user
    // window.Echo.private(`private-channel.${userId}`)
    //   .listen('.TestPrivateEvent', (data) => {
    //     console.log(`Received private event for user ${userId}:`, data);
    //     this.$buefy.toast.open(`Private Channel [User ${userId}]: ${data.message}`);
    //   });
    // window.Pusher = require("pusher-js");
    // window.Echo = new Echo({
    //   broadcaster: "pusher",
    //   key: "0831ba376a02083bbb94",
    //   cluster: "ap2",
    //   forceTLS: true,
    // });
    // // console.log('Laravel Echo initialized, listening for events.');
    // window.Echo.channel("toast-public-channel").listen(
    //   ".ToastApproved",
    //   (e) => {
    //     console.log("Received toast notification:", e);
    //     this.toast = e;
    //   }
    // );
  },
  methods: {
    // initializeWebSocket() {
    //   // Replace with the URL of your Node.js server
    //   this.socket = io('http://localhost:3000');

    //   // Subscribe to updates for a specific search term ID
    //   this.socket.emit('subscribeToSearchTerm', this.searchterm_id);

    //   // Listen for updates from the server
    //   this.socket.on('SearchTermResponseUpdate', (data) => {
    //     console.log('Received search term response update:', data);
    //     this.updateData = data;
    //   });
    // },
  },

};
</script>

<style lang="scss">
.fh_main_info-bar {

  background-color: white;
  width:100vw;
  height: 1.6rem;
}

.findhub_spa {
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  position: absolute;
  background-color: #e6f6f4;
 }

 .app-main {
  height: 100%;
 }

.findhub_spa-bg{
    // background-image: url("../public/img/beautiful-abstract.jpg");
    //   /* Add your image path here */
    // background-size: cover;
    //  /* Ensure the image covers the entire area */
    // background-position: center;
    // position: absolute;
    // width: 100vw;
    // height: 100vh;
    // top: 0;
    // left: 0;
    // filter: blur(3px);
}
.app-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

@media (max-width: 600px) {
  // Changed from 550.56px to 600px

  /* Adjust the max-width as needed */
  .app-main {
    padding: .3rem !important;
    /* Reduced padding for mobile */
  }

  .container is-fluid {

    padding: .5rem;
  }
}
</style>
