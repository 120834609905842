<template>
  <!-- <div v-if="mobileOS" class="app-promotion-banner">
    <p>Download our app:</p>
    <a
      v-if="mobileOS === 'iOS'"
      href="https://apps.apple.com/your-app-link"
      target="_blank"
    >
      <img src="appstore-icon.png" alt="App Store" />
    </a>
    <a
      v-if="mobileOS === 'Android'"
      href="https://play.google.com/store/apps/details?id=your-app-id"
      target="_blank"
    >
      <img src="playstore-icon.png" alt="Play Store" />
    </a>
  </div> -->
  <Nav />
  <div class="container is-fluid findhub_home_container_fluid">
    <div class="columns">
      <div class="column">
        <!-- <h3>User: {{ $currentUser() }}</h3> -->
        <h3 class="title is-1 is-primary" v-html="welcomeMessage"></h3>
        <div>
          <b-dropdown v-model="selectedLocation" aria-role="list">
            <template #trigger>
              <b-button
                :label="
                  selectedLocation ? selectedLocation.type : 'Select Location'
                "
                type="is-primary"
                icon-left="map-marker"
                icon-right="menu-down"
                expanded
                class="fh_home_location_selection-dropdown"
              />
              <div v-if="!selectedLocation" class="animated-arrow">
                <img
                  src="/icons/hand-left.png"
                  alt="Select Location"
                  style="max-width: 3rem"
                />
              </div>
            </template>

            <!-- Add tooltip to the dropdown button -->
            <b-tooltip
              type="is-dark"
              label="Select your preferred location from the list"
            >
              <b-dropdown-item
                v-for="(item, index) in filteredLocationSelect"
                :key="index"
                :value="item"
                type="is-primary is-light"
                aria-role="listitem"
              >
                <div class="media">
                  <b-icon class="media-left" icon="map-marker"></b-icon>
                  <div class="media-content">
                    <h3>{{ item.type }}</h3>
                    <small>{{
                      item.location.displayName ||
                      item.location.nick_name ||
                      "Unable to get location"
                    }}</small>
                  </div>
                </div>
              </b-dropdown-item>
            </b-tooltip>
          </b-dropdown>
          <b-input
            v-model="locationQuery"
            ref="locationInput"
            placeholder="Enter a place in UAE"
            icon="map-marker"
            @input="initializeGoogleAutocomplete"
            v-if="googleAutocompleteDisplay"
          ></b-input>
          <b-field
            v-if="locationStore.currentLocation"
            grouped
            class="is-align-items-center mt-3 is-1 is-primary"
            type="is-light"
          >
            <!-- <b-icon icon="map-marker" size="is-medium"></b-icon> -->
            {{ locationStore.currentLocation.displayName }}
            <span>
              <div
                class="fh_main-location-refresh_btn"
                @click="locationStore.fetchCurrentLocation"
              >
                <b-icon icon="mdi mdi-refresh-circle" type="is-primary">
                </b-icon>
              </div>
            </span>

            <!-- <b-button @click="locationStore.fetchCurrentLocation" icon-right="mdi mdi-refresh-circle"> -->
          </b-field>
          <b-field> </b-field>
        </div>
      </div>
    </div>
    <div class="columns findhub_home_container-main is-align-items-center">
      <div class="column">
        <div class="box findhub_home_div_box">
          <b-tabs v-model="activeTab">
            <b-tab-item
              label="Quick Search"
              icon-pack="mdi"
              icon="mdi mdi-magnify"
            >
              <!-- <b-field v-else>
                <b-button
                  @click="locationStore.fetchCurrentLocation"
                  icon-right="mdi mdi-cellphone-arrow-down"
                >
                </b-button>
              </b-field> -->

              <SearchInputCustom />
            </b-tab-item>

            <b-tab-item label="Image" icon-pack="mdi" icon="mdi mdi-image">
              <ImageSearchComponent />
            </b-tab-item>

            <!-- <b-tab-item label="Automobiles" icon-pack="mdi" icon="mdi mdi-car">
              <b-field label="Simple">
                <b-select placeholder="Select a name">
                  <option>Car</option>
                  <option>Motorcycle</option>
                  <option>Boat</option>
                </b-select>
              </b-field>
              <b-field label="Loading">
                <b-input placeholder="Loading" loading></b-input>
              </b-field>
            </b-tab-item> -->
          </b-tabs>
        </div>
      </div>
      <div class="column is-hidden-mobile">
        <div class="">
          <HomeCarousel />
        </div>
      </div>
    </div>
    <div class="columns findhub_home_container-product_tile">
      <div class="column">
        <div class="fh_title_box_shape">Active Categories</div>
        <div class="findhub_home_container-product_tile-wrapper">
          <div
            class="findhub_home_container-product_tile-item"
            v-for="category in pageStore.allCategories"
            :key="category.id"
          >
            <img
              :src="imageUrl(category.icon_image)"
              alt="Category Icon"
              class="category-icon"
            />
            <p class="category-name">{{ category.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="columns findhub_home_container-recent">
      <div class="column">
        <RecentSearche />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */ 
import { ref, computed, onMounted, defineAsyncComponent, watch } from "vue";
import { useAuthStore, useLocationStore ,usePageStore } from "../store";
import _ from "lodash";
import { MapUtil } from "../utils/map.util";
import SearchInputCustom from "../components/SearchInputCustom.vue";
import HomeCarousel from "@/components/HomeCarousel.vue";
import RecentSearche from "@/components/RecentSearche.vue";
import Nav from "@/components/Nav.vue";

export default {
  components: {
    SearchInputCustom,
    Nav,
    HomeCarousel,
    RecentSearche,
    LoginModal: defineAsyncComponent(() =>
      import("@/components/LoginModal.vue")
    ),
    ImageSearchComponent: defineAsyncComponent(() =>
    import("@/components/search/ImageSearchComponent.vue"),
    ),
  },
  
  created() {
    this.authStore = useAuthStore();
    this.locationStore = useLocationStore();
    this.pageStore = usePageStore();
  },
  data() {
    return {
      activeTab: 0,
      locationQuery: "",
      googleAutoComplete: null,
      locationInput: null,
      mobileOS: this.isMobile(),
      dropFiles: [],
      nearbyAddresses: [],
      selectedAddress: null, // Initialize as null
      googleAutocompleteDisplay:false,
      locationSelector:null,
      isCurrent: true,
      isNearby: false,
      isDefault: false,
      locationSelect: [],
      selectedLocation: null,
    };
  },
  computed: {
    welcomeMessage() {
      const currentHour = new Date().getHours();
      let greeting = "Welcome";

      if (currentHour < 12) {
        greeting = "Good Morning";
      } else if (currentHour < 18) {
        greeting = "Good Afternoon";
      } else {
        greeting = "Good Evening";
      }

      const userName =
        this.authStore.isAuthenticated &&
        this.authStore.user &&
        this.authStore.user.name
          ? this.authStore.user.name
          : "Guest";

      return `<span class="findhub_home_welcome_greeting">${greeting}</span><br><span class="findhub_home_welcome_name">${userName}</span>`;
    },
    // nearbyAddresses() {
    //   if (this.authStore.isAuthenticated) {
    //     const addresses = this.authStore.getUserNearbyAddresses(
    //       this.locationStore.currentLocation.lat,
    //       this.locationStore.currentLocation.lng
    //     );
    //     if (addresses.length > 0) {
    //       this.selectedAddress = addresses[0];
    //       return addresses;
    //     }
    //   }
    //   return [];
    // },
    
    filteredLocationSelect() {
      return this.locationSelect.filter(item => item.type !== 'Error');
    },
  },
  methods: {
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      return false;
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        window.initMap = resolve;
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.VUE_APP_GOOGLE_API_KEY}&language=en&callback=initMap`;
        script.async = true;
        script.setAttribute("loading", "lazy");
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    initializeGoogleAutocomplete() {
      if (this.googleAutocompleteDisplay) {
        const inputElement = this.$refs.locationInput?.$el?.querySelector('input');

        if (inputElement instanceof HTMLInputElement) {
          this.googleAutoComplete = new google.maps.places.Autocomplete(inputElement, {
            types: ["(regions)"],
            componentRestrictions: { country: "AE" },
          });

          this.googleAutoComplete.addListener("place_changed", () => {
            const placeInfo = this.googleAutoComplete.getPlace();
            if (placeInfo && _.has(placeInfo, "address_components")) {
              const placeData = MapUtil.parseAddressComponents(
                placeInfo.address_components
              );
              this.locationStore.setCurrentLocation(
                placeInfo.geometry.location.lat(),
                placeInfo.geometry.location.lng(),
                MapUtil.getLocalDisplayName(placeData)
              );
              console.log("Location updated successfully");
            }
          });
        } else {
          console.error("locationInput is not a valid input element");
        }
      }
    },
    async fetchNearbyAddresses() {
      if (this.authStore.isAuthenticated) {
        try {
          const addresses = await this.authStore.getUserNearbyAddresses(
            this.locationStore.currentLocation.lat,
            this.locationStore.currentLocation.lng
          );
          if (addresses.length > 0) {
            // console.log(addresses) 
            this.selectedAddress = addresses[0];
            this.nearbyAddresses = addresses;
          } else {
            this.nearbyAddresses = [];
            this.setDefaultLocation();
          }
        } catch (error) {
          console.error("Error fetching nearby addresses:", error);
          this.nearbyAddresses = [];
          this.setDefaultLocation();
        }
      } else {
        this.nearbyAddresses = [];
        this.setDefaultLocation();
      }
    },
    setDefaultLocation() {
      if (this.locationStore.currentLocation && this.locationStore.currentLocation.displayName) {
        this.selectedAddress = { nick_name: this.locationStore.currentLocation.displayName };
      } else {
        this.selectedAddress = null;
      }
    },
    async updateLocationSelect() {
      // console.log("Starting updateLocationSelect");

      // Clear the locationSelect array to avoid duplicates
      this.locationSelect = [];

      if (this.authStore.isAuthenticated && this.authStore.user) {
        // console.log("User is authenticated and user data is available");

        if (this.locationStore.currentLocation) {
          // console.log("Current location is available:", this.locationStore.currentLocation);

          this.locationSelect.push({
            location: this.locationStore.currentLocation,
            type: "Current location"
          });
          // console.log("Added current location to locationSelect");
        }

        const nearbyAddresses = await this.authStore.getUserNearbyAddresses(
          this.locationStore.currentLocation.lat,
          this.locationStore.currentLocation.lng
        );
        // console.log("Nearby addresses fetched:", nearbyAddresses);

        if (nearbyAddresses && nearbyAddresses.length > 0) {
          this.locationSelect.push({
            location: nearbyAddresses[0],
            type: "Nearby Address"
          });
          // console.log("Added nearby location to locationSelect");
        }

        const userAddresses = await this.authStore.getUserAddresses();
        // console.log("User addresses fetched:", userAddresses);

        const defaultAddress = userAddresses.find(address => address.is_default);
        if (defaultAddress) {
          this.locationSelect.push({
            location: defaultAddress,
            type: "Default Address"
          });
          // console.log("Added default location to locationSelect");
        }
      } else {
        console.log("User is not authenticated");

        if (this.locationStore.currentLocation) {
          console.log("Current location is available:", this.locationStore.currentLocation);

          this.locationSelect.push({
            location: this.locationStore.currentLocation,
            type: "notAuthenticated"
          });
          console.log("Added current location to locationSelect with type 'notAuthenticated'");
        } else {
          console.log("No current location available");

          this.locationSelect.push({
            location: null,
            type: "Error"
          });
          console.log("Added error entry to locationSelect");
        }
      }

      // console.log("Final locationSelect array:", this.locationSelect);
    },
    setDefaultSelectedLocation() {
      // Find the current location in the locationSelect array
      const currentLocation = this.locationSelect.find(
        (item) => item.type === "Current location"
      );

      // Set the selectedLocation to the current location if it exists
      if (currentLocation) {
        this.selectedLocation = currentLocation;
      }
    },
  },
  async mounted() {
    try {
      await this.loadGoogleMapsScript();
      this.initializeGoogleAutocomplete();
      this.locationStore.registerFCMToken();
      if (this.authStore.isAuthenticated) {
        // Implement your onPostLoginSuccessHandler here if needed
      }
      this.locationStore.checkAndRequestLocation();
      await this.fetchNearbyAddresses(); // Call the async method here
      await this.updateLocationSelect();
      this.setDefaultSelectedLocation(); // Set the default selected location
      await this.pageStore.getAllCategories();
    } catch (error) {
      console.error("Error during component mount:", error);
    }
    this.mobileOS = this.isMobile();
  },
  watch: {
    'locationStore.currentLocation': function() {
      this.fetchNearbyAddresses(); // Call the async method when location changes
      this.setDefaultSelectedLocation(); // Update the default selected location
    },
    selectedLocation: function(newVal, oldVal) {
      // console.log('Selected location changed from', oldVal, 'to', newVal);
      
      // Check if newVal has the necessary properties
      if (newVal && newVal.location) {
        if (newVal.type === "Current location") {
          const { lat, lng } = newVal.location;
          this.locationStore.setSelectedLocation(lat, lng);
        } else {
          const { latitude, longitude } = newVal.location;
          this.locationStore.setSelectedLocation(parseFloat(latitude), parseFloat(longitude));
        }
      } else {
        console.error('New selected location does not have valid lat/lng');
      }
    },
    'authStore.isAuthenticated': function() {
      this.updateLocationSelect(); // Refresh location select when authentication status changes
    },
  },

};
</script>

<style scoped>


.left-column,
.right-column {
  flex: 1;
}

.findhub_home_welcome_greeting {
  text-transform: uppercase !important;
}

.findhub_home_welcome_name {
  color: aquamarine;
}

.findhub_home_container_fluid {
  /* padding-top: 5rem;
  height: 85%; */
  display: flex;
  flex-direction: column;

  padding: 2rem 2rem;
}

.fh_main-location-refresh_btn {
  cursor: pointer;
  margin-left: 2px;
}
.fh_home_location_selection-dropdown{
  position: relative;
}
.findhub_home_container-main {
  height: 40rem;
}

.animated-arrow {
  animation: bounce 1s infinite;
  position: absolute; /* Adjust as necessary */
  /* Add any additional styling to position the arrow */
  top: 0;
  right: -3rem;
}

.findhub_home_div_box{
  height: auto;
}
.findhub_home_container-product_tile{
  margin-top: -10rem;
  margin-bottom: 5rem;
}
.findhub_home_container-recent{

}
.findhub_home_container-product_tile-wrapper{
  display: flex;
}
.findhub_home_container-product_tile-item {
  background: linear-gradient(to bottom right, #d9f2ef,#00655884);
  width: 6rem;
  height: 6rem;
  padding: .5rem 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px #003b3325;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: .5rem;
  position: relative;
}

.findhub_home_container-product_tile-item:not(:last-child) {
  margin-right: .5rem;
}

/* .category-icon{
  width: 3rem;
} */
 .category-name{
  position: absolute;
  bottom: -2rem;
  font-weight: 600;
  color: #004c41;
 }

@keyframes bounce {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px); /* Adjust the bounce height */
  }
}

</style>

















