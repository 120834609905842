<template>
  <div class="page-container">
    <Nav />
    <div class="main-content">
      <div class="columns header-result">
        <div class="column">
          <div class="card">
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <figure class="image">
                    <p
                      class="image is-128x128 has-text-centered is-flex is-justify-content-center is-align-content-center"
                    >
                      <img
                        v-if="
                          termDetails.category &&
                          termDetails.category.icon_image
                        "
                        :src="termDetails.category.icon_image"
                        alt="Category Icon"
                        style="height: 120px; width: auto"
                      />
                      <img
                        v-else
                        src="https://bulma.io/assets/images/placeholders/256x256.png"
                        alt="Placeholder Image"
                      />
                    </p>
                  </figure>
                </div>
                <div class="media-content">
                  <div class="fh_search_result_banner-message-conatiner">
                    <div class="fh_search_result_result_banner-message">
                      <p class="title is-4 mb-1">
                        Hello {{ $currentUser().name }}
                      </p>
                      We understood that you have searched for
                      <strong> {{ termDetails.term }}</strong> in your area. We
                      are contacting all seller, please find status below.
                    </div>
                    <div class="fh_search_result_result_banner-notification">
                      <b-button type="is-danger" icon-left="mdi mdi-stop-circle"
                        >Stop</b-button
                      >
                    </div>
                  </div>
                  <hr style="margin: 0.5rem 0" />
                  <div class="fh_search_result_result_banner-infoarea">
                    <div
                      class="fh_search_result_result_banner-item fh_search_result_result_banner-status"
                    >
                      <div class="fh_search_result_result_banner-status-label">
                        Status
                      </div>
                      <div
                        class="fh_search_result_result_banner-status-content"
                      >
                        {{ dynamicStatusMessage }}
                      </div>
                    </div>
                    <!-- <div class="fh_search_result_result_banner-notification">
                      <img src="/icons/notification.gif" alt="Notification" style="max-width: 3rem" />
                      <b-field>
                        <b-switch :value="true"> In-App </b-switch>
                        <b-switch :value="true"> SMS </b-switch>
                      </b-field>
                      <div>
                        <img
                          :src="
                            sms_notification_requested
                              ? '/icons/notification_app_active.gif'
                              : '/icons/notification_app.gif'
                          "
                          alt="Notification Bell"
                          style="height: 64px; width: auto; cursor: pointer"
                          @click="toggleAppNotification"
                        />
                      </div>
                      <div>
                        <img
                          :src="
                            sms_notification_requested
                              ? '/icons/notification_sms_active.gif'
                              : '/icons/notification_sms.gif'
                          "
                          alt="Notification Bell"
                          style="height: 64px; width: auto; cursor: pointer"
                          @click="promptWithCheckbox"
                        />
                      </div>
                    </div> -->

                    <!-- <div class="fh_search_result_result_banner-distance">
                                 <b-select placeholder="Default" expanded>
                        <option value="flint">Flint</option>
                        <option value="silver">Silver</option>
                      </b-select>
                    </div> -->

                    <div class="fh_search_result_result_banner-action"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-quarter">
          <div class="box fh_search_result_header_status-tiles">
            <div class="fh_search_result_header_status-tile">
              <p class="fh_search_result_header_status-tile-label">
                Seller Notified
              </p>
              <p class="fh_search_result_header_status-tile-count">
                {{
                  termDetails.notified_count ? termDetails.notified_count : 0
                }}
              </p>
            </div>
            <div class="fh_search_result_header_status-tile">
              <p class="fh_search_result_header_status-tile-label">Timer</p>
              <div class="fh_search_result_header_status-tile-notify">
                <p>{{ formattedDuration }}</p>
              </div>
            </div>
            <div class="fh_search_result_header_status-tile">
              <p class="fh_search_result_header_status-tile-label">
                Seller Responded
              </p>
              <p class="fh_search_result_header_status-tile-count">
                {{ termDetails.responses ? termDetails.responses.length : 0 }}
              </p>
            </div>
            <div class="fh_search_result_header_status-tile">
              <p class="fh_search_result_header_status-tile-label">
                Checking Responses
              </p>
              <p class="fh_search_result_header_status-tile-count">
                <VueSpinnerHourglass
                  class="is-large"
                  :size="30"
                  :color="'#000'"
                  :loading="true"
                />
              </p>
            </div>
            <!-- <div class="box fh_search_result_result">
              <div class="has-text-centered">
                <p class="heading">Sellers were notified</p>
                <p class="title">
                  {{
                    termDetails.notified_count ? termDetails.notified_count : 0
                  }}
                </p>
              </div>
              <div class="has-text-centered">
                <p class="heading">Sellers responded</p>
                <p class="title">
                  
                </p>
              </div>
              <b-field>
                <b-button type="is-danger" expanded> Cancel</b-button></b-field>
            </div> -->
          </div>
        </div>
      </div>
      <br />

      <div class="columns inner-main-content" v-if="selected_term_response">
        <div class="column">
          <div class="card fh_results_cards" v-if="!isLoading">
            <!-- Main container -->
            <header class="card-header">
              <p class="card-header-title">Search Responses</p>
              <nav class="level">
                <!-- Left side -->
                <div class="level-left">
                  <div class="level-item">
                    <div class="field has-addons">
                      <p class="control">
                        <b-input
                          placeholder="Search"
                          size="is-small"
                          icon="account"
                        >
                        </b-input>
                      </p>
                      <p class="control mr-2">
                        <b-select placeholder="Sort" size="is-small" expanded>
                          <option value="flint">Distance</option>
                          <option value="silver">Price</option>
                          <option value="silver">Rating</option>
                        </b-select>
                      </p>
                    </div>
                  </div>
                </div>
              </nav>
            </header>
            <div class="card-content px-3 py-1">
              <div
                class="card"
                v-for="term in showable_results"
                :key="term.id"
                :class="{
                  'fh_search_result-list-active':
                    selected_term_response &&
                    selected_term_response.id === term.id,
                }"
              >
                <div class="card-content px-4 pt-4 mt-2">
                  <div class="media mb-1">
                    <div class="media-left">
                      <figure class="image">
                        <img
                          v-if="term.seller && term.seller.logo"
                          :src="imageUrl(term.seller.logo)"
                          alt="Category Icon"
                          style="height: 50px; width: auto"
                        />
                        <img
                          v-else
                          src="https://bulma.io/assets/images/placeholders/64x64.png"
                          alt="Placeholder Image"
                        />
                      </figure>
                    </div>
                    <div class="media-content">
                      <p class="">
                        Seller Name :
                        <strong> {{ term.seller.business_name }}</strong>
                        <br />
                        Location :
                        <small>{{ term.seller.city.name }} </small>
                      </p>
                    </div>
                  </div>
                  <div class="content" style="height: 3.5rem">
                    <div class="columns is-vcentered">
                      <div class="column is-one-quarter">
                        <b-rate
                          v-model="term.seller.rating"
                          :icon-pack="packs"
                          :icon="icons"
                          :max="5"
                          :size="sizes"
                          :class="['response_list_rating', 'no-bottom-margin']"
                          disabled
                        >
                        </b-rate>
                      </div>
                      <div class="column is-flex is-flex-direction-row-reverse">
                        <div class="fh_search_results_icons-tile">
                          <span class="icon is-small custom-icon">
                            <img src="/icons/9.svg" alt="Delivery Icon" />
                          </span>
                          <span>N/A</span>
                        </div>
                        <div class="fh_search_results_icons-tile">
                          <span class="icon is-small custom-icon">
                            <img src="/icons/32.svg" alt="Delivery Icon" />
                          </span>
                          <span>{{
                            (term.distance / 1000).toFixed(2) + " KM"
                          }}</span>
                        </div>
                        <!-- <div class="fh_search_results_icons-tile">
                          <span class="icon is-small custom-icon">
                            <img src="/icons/9.svg" alt="Delivery Icon" />
                          </span>
                          <span style="margin-top: -8px">Delivery</span>
                        </div>
                        <div class="fh_search_results_icons-tile">
                          <span class="icon is-small custom-icon">
                            <img src="/icons/9.svg" alt="Delivery Icon" />
                          </span>
                          <span style="margin-top: -8px">Delivery</span>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
                <footer class="card-footer">
                  <p class="card-footer-item p-1" style="line-height: 1rem">
                    <span>
                      <strong>{{ formatAmount(term.price) }}</strong>
                      AED</span
                    >
                  </p>
                  <p class="card-footer-item" style="line-height: 1rem">
                    <span>
                      View <a @click.prevent="onClickEnquiry(term)">Offer</a>
                    </span>
                  </p>
                </footer>
              </div>
            </div>

            <div class="" v-for="term in showable_results" :key="term.id">
              <div
                class="columns"
                v-on:click.prevent="
                  // markForEnquiry(term);
                  onClickEnquiry(term)
                "
              >
                <div class="column is-narrow">
                  <!-- <img class="sr-image-width" :src="imageUrl(term.seller.logo)" /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <SelectedResponseCard
            v-if="selected_term_response"
            :selected_term_response="selected_term_response"
            :term="termDetails"
          />
        </div>
        <div class="column" v-if="selected_term_response">
          <EnquiryChat
            :sellerResponse="selected_term_response"
            :term="termDetails"
          />
        </div>
      </div>
      <div class="columns inner-main-content empty-response" v-else>
        <div class="box fh_search_result-no_response-box">
          <img src="/icons/searching.gif" alt="" />
          <div class="box-header">
            <h1 class="title">No Responses Found</h1>
          </div>
          <div class="box-content">
            <p>
              We are still searching for more seller responses. <br />
              Please wait, and you will get a response very soon. Also, you can
              select the following <br />
              notification method if you would like to close the window, and we
              will notify you upon receiving a response.
            </p>
            <div class="fh_search_result-no_response-notification-toggle">
              <b-field>
                <b-switch
                  v-model="termDetails.sms_notification_requested"
                  @input="toggleSmsNotification"
                >
                  SMS</b-switch
                >
                <b-switch
                  v-model="termDetails.in_app_notification"
                  @input="toggleInAppNotification"
                  >In-App</b-switch
                >
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { useSearchStore, useAuthStore, useCartStore } from "@/store";
import { computed } from "vue";
import { useRoute } from "vue-router";
// import EnquiryChat from "@/components/EnquiryChat.vue";
import { DialogProgrammatic as Dialog } from "buefy";
import { VueSpinnerHourglass } from "vue3-spinners";
// import PageLoader from "@/components/PageLoader.vue";
import { defineAsyncComponent, toRaw } from "vue";
import loader from "sass-loader";
import io from 'socket.io-client';
import Nav from "@/components/Nav.vue";
import moment from 'moment-timezone'; // Import moment-timezone for time zone handling


const PageLoader = defineAsyncComponent(() =>
  import("@/components/PageLoader.vue")
);
const SelectedResponseCard = defineAsyncComponent(() =>
  import("@/components/SelectedResponseCard.vue")
);

const EnquiryChat = defineAsyncComponent(() =>
  import("@/components/EnquiryChat.vue")
);

export default {
  props: {
    searchterm_id: {
      type: String,
      required: true,
    },
  },
  components: {
    EnquiryChat,
    PageLoader,
    VueSpinnerHourglass,
    SelectedResponseCard,
    Nav,
  },
  data() {
    return {
      searchResults: [], // Initialize searchResults
      // searchtermIdFromRoute: this.$route.params.searchterm_id, // Get searchterm_id from route params
      searchStore: useSearchStore(), // Assign searchStore to this
      cartStore: useCartStore(), // Assign cartStore to this
      termDetails: {
        responses: [],
      },
      rate: 4.6,
      maxs: 5,
      sizes: '',
      packs: 'mdi',
      icons: 'star',
      selected_term_response: null,
      term_id: this.$route.params.id,
      product_listing: true,
      isLoading: false,
      isFullPage: false,
      sms_notification_requested: false,
      in_app_notification: true,
      enquiries: {
        loading: true,
        data: [],
        pagination: {},
      },
      cart: null,
      product_detail: null,
      sort_order: 2,
      selectedResponseExpiryTimer: {
        value: 0,
        intervalId: null,
      },
      meta: {
        actions: {
          onPageLoad: {
            loading: false,
          },
          onProceedAction: {
            loading: false,
          },
        },
      },
      cart: [],
      currentMenu: { icon: 'account-group', text: 'People' },
      menus: [
        { icon: 'account-group', text: 'People' },
        { icon: 'shopping-search', text: 'Orders' },
        { icon: 'credit-card-multiple', text: 'Payments' },
        { icon: 'dolly', text: 'Logistics' },
        { icon: 'clock-check', text: 'Jobs' },
        { icon: 'cart-arrow-right', text: 'Cart' },
        { icon: 'settings', text: 'Configuration' }
      ],
      checkboxValue: false, // Add a data property for the checkbox
      timer: null, // Add a timer property
      currentTime: moment(), // Add a reactive property for the current time
    };
  },
  computed: {
    search_results_status: function () {
      if (this.searchResults.responses && this.searchResults.responses.le > 0) {
        return "SELLERS_NOT_FOUND";
      }
      return "SELLERS_FOUND";
    },
    search_status: function () {
      if (this.searchResults.status === "cancelled") {
        return "Cancelled";
      }
      return "Searching";
    },
    showable_results: function () {
      const responses = this.termDetails.responses;
      return responses;
    },

    selected_response_timer_display: function () {
      if (this.selectedResponseExpiryTimer.value > 0) {
        const diff = moment.duration(
          this.selectedResponseExpiryTimer.value,
          "seconds"
        );
        return `${diff.days()}d ${diff.hours()}h ${diff.minutes()}m`;
      }
      return "0 seconds";
    },
    formattedDuration() {
      // Convert the created_at time from UTC to GST (UTC+4:30)
      const localTime = moment.utc(this.termDetails.created_at).tz('Asia/Dubai'); // Adjust to GST
      const duration = moment.duration(this.currentTime.diff(localTime)); // Use currentTime for calculation

      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      return `${hours}h ${minutes}m ${seconds}s`; // Format as "Xh Ym Zs"
    },
    dynamicStatusMessage() {
      const localTime = moment.utc(this.termDetails.created_at).tz('Asia/Dubai');
      const duration = moment.duration(this.currentTime.diff(localTime));
      const minutesElapsed = duration.asMinutes();

      if (this.termDetails.responses.length > 0) {
        return "We received below response, still trying to get more.";
      } else if (minutesElapsed <= 5) {
        return "We are contacting";
      } else if (minutesElapsed > 5 && minutesElapsed < 10 && this.termDetails.responses.length === 0) {
        return "No Responses received, you can still wait or set notification option below, we will notify you when a response is received from seller";
      }
      return "Status unknown"; // Default message if none of the conditions are met
    },
  },
  async beforeCreated() {

  },
  async created() {
    console.log("SearResult Vue is created, but not yet mounted to DOM");
    // this.interval = setInterval(() => this.fetchSearchResponses(), 10000)
    this.isLoading = true;
    this.initializeWebSocket();
    await this.fetchSearchResponses();
  },
  async mounted() {
    console.log("SearResult Vue is mounted and available in the DOM");
    console.log(
      "When mounted we found the User :: " + this.$currentUser().name
    );
    this.isLoading = false;

    // Call the checkCart method after the page has loaded
    // await this.checkCart();
    this.startTimer(); // Start the timer when the component is mounted
  },
  beforeUnmount() {
    if (this.socket) {
      this.socket.disconnect();
    }
    clearInterval(this.timer); // Clear the timer when the component is unmounted
  },
  methods: {
    async cancelSearch() {
      try {
        const { result, dialog } = await this.$buefy.dialog.confirm({
          title: "Cancel Search",
          message: "Are you sure you want to <b>cancel</b> your search?",
          closeOnConfirm: false,
          hasIcon: true,
          onConfirm: function (result, dialog) {
            dialog.close();
            this.searchStore.cancel(this.searchterm_id);
          },
        });
        // if (result) {
        //   console.log(result);
        //   this.$buefy.dialog.close();
        //   // await SearchService.cancel(this.searchterm_id);
        // }
      } catch (e) {
        console.log(e);
      }
    },
    initializeWebSocket() {
      // Replace with the URL of your Node.js server
      this.socket = io(process.env.VUE_APP_WEBSOCKET_URL);
      // Subscribe to updates for a specific search term ID
      this.socket.emit('subscribeToSearchTerm', this.searchterm_id);
      // Listen for updates from the server
      this.socket.on('SearchTermResponseUpdate', (data) => {
        console.log('Received search term response update:', data);
        this.updateData = data;
        // this.fetchSearchResponses();
        // {{ edit_1 }}: Check if data.id exists in termDetails.responses
        const exists = this.termDetails.responses.some(response => response.id === data.id);
        if (this.termDetails.responses.length === 0) {
          this.termDetails.responses.push(data);
          this.isLoading = false;
        }
        if (!exists) {
          if (this.termDetails.responses.length === 0) {
            this.termDetails.responses.push(data); // Add data if responses array is empty
          }
        }
        // {{ edit_1 }}: Check if the response ID does not exist and push data if conditions are met
        if (this.termDetails.responses.length > 0 && this.termDetails.responses.some(response => response.id !== data.id)) {
          this.termDetails.responses.push(data); // Add data if response ID is not found
        }
        if (
          this.showable_results.length > 0 &&
          this.selected_term_response == null
        ) {
          this.selected_term_response = this.showable_results[0];
        } else if (this.selected_term_response !== null) {
          // find the ID from the results and set to the selected_term_response
          this.selected_term_response = _.find(this.showable_results, {
            id: this.selected_term_response.id,
          });
        }
        // Add toast notification
        this.$buefy.toast.open({
          message: 'New response received!',
          type: 'is-success',
          duration: 5000,
        });
      });
    },
    logSearchResults() {
      console.log("Testing ......");
      console.log(this.searchResults);
      // this.searchResults.responses.forEach(response => {
      //   console.log(response);
      // });
    },
    onLoaderCancel() {
      // Handle cancellation of the loader
      this.meta.actions.onPageLoad.loading = false;
      console.log("Loader was canceled");
    },
    async markForEnquiry(stResponse) {
      try {
        // stResponse._meta.actions.onProceed.loading = true
        await SearchService.markForEnquiry(stResponse.id);
      } catch (e) {
        this.errors = e.response;
        console.log("this.errors", e.response);
      }
    },
    onClickEnquiry(term) {
      console.log(term);
      this.selected_term_response = term;
    },
    async fetchUserData() {
      const authStore = useAuthStore();
      const userData = await authStore.getUserDataFromLocalStore(); // Call the new method
      const userId = userData ? userData.id : null; // Safely access user ID

      // Fetching logic
      this.fetchSearchResults(userData);
    },
    async fetchSearchResults(userData) {
      this.termDetails = await this.searchStore.retriveQuickSearchResults(
        userData.id,
        parseInt(this.searchterm_id)
      );
    },
    async fetchSearchResponses() {
      this.isLoading = true;
      if (
        !this.termDetails.responses ||
        this.termDetails.responses.length === 0
      ) {
        const response = await this.searchStore.viewSearchTerm(
          this.searchterm_id
        );
        this.termDetails = response;
        if (this.termDetails.responses.length > 0) {
          this.isLoading = false;
        }
        // Select the first response as default when there are results
        if (
          this.showable_results.length > 0 &&
          this.selected_term_response == null
        ) {
          this.selected_term_response = this.showable_results[0];
        } else if (this.selected_term_response !== null) {
          // find the ID from the results and set to the selected_term_response
          this.selected_term_response = _.find(this.showable_results, {
            id: this.selected_term_response.id,
          });
        }
      } else {
        this.isLoading = true;
        // You might want to handle the response here as well
      }
    },
    getCurrentUserId() {
      // Access the global currentUser
      const currentUserId = this.$currentUser().id;

      if (currentUserId) {
        console.log("Current User ID:", currentUserId);
      } else {
        console.log("User is not authenticated");
      }
    },
    fetchTermInfo() {
      if (this.termDetails.responses.length > 0) {
        this.selected_term_response = this.termDetails.responses[0];
        console.log("Selected the first response from termDetails.responses");
      } else if (this.selected_term_response !== null) {
        // find the ID from the results and set to the selected_term_response
        this.selected_term_response = _.find(this.showable_results, {
          id: this.selected_term_response.id,
        });
        console.log(
          "Found the ID from the results",
          this.selected_term_response
        );
      }
    },


    async toggleSmsNotification() {
      console.log("SMS notification Toggled");
      this.termDetails.sms_notification_requested = !this.termDetails.sms_notification_requested;
      const data = {
        sms_notification_requested: this.termDetails.sms_notification_requested,
        in_app_notification: this.termDetails.in_app_notification,
      }
      const response = await this.searchStore.update(this.searchterm_id,data);
      console.log(response);

      const message = this.termDetails.sms_notification_requested 
        ? 'You have successfully set SMS notification for all responses' 
        : 'You have decided not to receive SMS for responses';
      this.$buefy.dialog.alert(message);
    },
    async toggleInAppNotification() {
      console.log("In-App notification Toggled");
      this.termDetails.in_app_notification = !this.termDetails.in_app_notification;
      const data = {
        sms_notification_requested: this.termDetails.sms_notification_requested,
        in_app_notification: this.termDetails.in_app_notification,
      }
      const response = await this.searchStore.update(this.searchterm_id, data);
      console.log(response);
      const message = this.termDetails.in_app_notification 
        ? 'You have successfully set In-App notification for all responses' 
        : 'You have decided not to receive In-App notifications for responses';
      this.$buefy.dialog.alert(message);
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime = moment(); // Update currentTime every second
      }, 1000); // Update every second
    },
  },
};
</script>

<style>
.term_response {
  border-radius: 5px;
}

.response_list_rating {
  margin-bottom: 0px;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
  /* Ensure it overrides any other margin settings */
}

.custom-icon {
  height: 2rem !important;
  width: 2rem !important;
}

.fh_search_results_icons-tile {
  display: flex;
  width: 72px;
  flex-direction: column;
  background-color: #f6d3937e;
  border-radius: 5px;
  /* Set border radius */
  transition: transform 0.3s ease;
  /* Add transition for animation */
  padding: .2rem;
  color: #BF7E04;
  box-shadow: 0 2px 4px #BF7E04(0, 0, 0, 0.1);
  margin-right: 8px;
  align-items: center;
  font-size: .8rem
    /* Add small box shadow */

}

.fh_search_results_icons-tile:hover {
  transform: scale(1.01);
  /* Scale up on hover */
  box-shadow: 0 4px 8px #BF7E04(0, 0, 0, 0.2);
  /* Increase box shadow on hover */
}

@media (max-width: 768px) {

  /* Adjust the max-width as needed */
  .container {
    padding-left: 16px;
    /* Reduced padding for mobile */
    padding-right: 16px;
    /* Reduced padding for mobile */
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 2rem);

  /* Deduct 10px for the alert bar */
}

.empty-response{
          justify-content: center;
            align-items: center;
}

.main-content {
  flex: 1;
  /* Take the remaining space */
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;

}

.header-result {
  /* height: 180px; */

  /* Optional: for visualization */
}

.inner-main-content {
  flex: 1;
  /* Expand to fill available height */
  display: flex;
}

.column {
  /* flex: 1; */
  /* Each column takes equal space */
  /* padding: 10px;
  border: 1px solid #ccc; */
  /* Optional: for visualization */
}

.fh_results_cards {
  height: 95%
}

.fh_search_result_result_banner-item {
  background-color: #b0e4dd;
  padding: .2rem .8rem;
  border-radius: .3rem;

}

.fh_search_result_result_banner-status {
  flex-grow: 1;
  height: 3rem;
  display: flex;
}

.fh_search_result_result_banner-status-label {
    display: flex;
      align-content: center;
      align-items: center;
      padding: 0 .5rem;

}
.fh_search_result_result_banner-status-content {
  background-color: white;
  width: 100%;
  margin-left: .3rem;
  padding-left: .3rem;
  font-style: italic;
}

.fh_search_result-list-active {
  border-right: solid 5px #F2C36B;
}

.fh_search_result_header_status-tiles {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  gap: .2rem;
}

.fh_search_result_header_status-tile {
  width: 49%;
  height: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d9f2ef;
  border-radius: 5px;
}
.fh_search_result_header_status-tile-notify{
  width: 30%;
    height: 75%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
}


.fh_search_result_header_status-tile-label {
  width: 65%;
  padding: .5rem;
}

.fh_search_result_header_status-tile-count {
  background-color: #b0e4dd;
  padding: 1rem;
  border-radius: 5px;
  font-weight: 600;
}

.fh_search_result_result_banner-infoarea {
  display: flex;
  justify-content: space-between;
  margin-top: .2rem;
  gap: .2rem;
}

.fh_search_result_result_banner-notification {
 display: flex;
  position: relative;
flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;  
  
}

.fh_search_result_result_banner-notification-checkbox .label:not(:last-child) {
  margin-bottom: 0;
}

.fh_search_result_banner-message-conatiner {
  display: flex;
  justify-content: space-between;
}

.fh_search_result_result_banner-notification-label {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 0 .2rem;
  font-size: 1.5rem;
}
.fh_search_result-no_response-box {
  padding: 6rem 3rem;
  text-align: center;
}
.fh_search_result-no_response-notification-toggle{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  }
</style>

