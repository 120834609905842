/* eslint-disable */
import axios from "axios";
export const elasticsearchService = {
  async autoSuggestElastic(query) {
    // checkAuthentication();

    const data = {
      query: {
        match: {
          term: query,
        },
      },
    };

    // Dynamically pick the server URL based on the environment
    const elasticsearchServer = process.env.VUE_APP_ELASTICSEARCH_SERVER;

    // Set up the headers
    const headers = {
      "Content-Type": "application/json",
      // Disable X-CSRF-Token by not including it in headers
    };

    // Add Basic Authorization header if in production
    if (process.env.NODE_ENV === "production") {
      const username = process.env.VUE_APP_ELASTICSEARCH_USERNAME;
      const password = process.env.VUE_APP_ELASTICSEARCH_PASSWORD;

      const authToken = btoa(`${username}:${password}`); // Encode to Base64

      headers["Authorization"] = `Basic ${authToken}`;
    }

    // Make the API request
    return await axios.get(
      `http://localhost:9200/search_term_details/_search`,
      {
        headers: headers,
        data: data,
        withCredentials: false, // Disable sending cookies and CSRF tokens
      }
    );
  },
  async matchKeywordsFromTensorFlowResult(text) {
    const elasticsearchServer = process.env.VUE_APP_ELASTICSEARCH_SERVER;

    // Define the search body
    const data = {
      _source: ["id", "name", "category", "category_id"], // Specify fields to return
      size: 2, // Limit the number of results
      query: {
        match: {
          name: text, // Match the text
        },
      },
      sort: [
        {
          _score: {
            order: "desc", // Sort by score in descending order
          },
        },
      ],
    };

    // Set up the headers
    const headers = {
      "Content-Type": "application/json",
    };

    // Add Basic Authorization header if in production
    if (process.env.NODE_ENV === "production") {
      const username = process.env.VUE_APP_ELASTICSEARCH_USERNAME;
      const password = process.env.VUE_APP_ELASTICSEARCH_PASSWORD;
      const authToken = btoa(`${username}:${password}`); // Encode to Base64

      headers["Authorization"] = `Basic ${authToken}`;
    }

    try {
      // Make the POST request to Elasticsearch
      const response = await axios.post(
        `http://localhost:9200/keywords_index/_search`,
        data,
        {
          headers: headers,
          withCredentials: false, // Disable sending cookies and CSRF tokens
        }
      );

      // Return only the hits array from the response
      return response.data.hits.hits;
    } catch (error) {
      console.error("Error fetching Elasticsearch results:", error);
      return [];
    }
  },
};
