<template>
  <div>
    <div class="fh_title_box_shape">Recent Searches</div>
    <div class="columns">
      <div
        class="column is-2"
        v-for="search in recentSearches.slice(0, 5)"
        :key="search.id"
      >
        <div class="box p-2">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64">
                <img src="/icons/geolocation.png" />
              </p>
            </figure>
            <div class="media-content">
              <div class="content">
                <p>
                  {{
                    search.term.length > 15
                      ? search.term.substring(0, 15) + "..."
                      : search.term
                  }}
                </p>
                <b-tag
                  :type="
                    search.status === 'responded'
                      ? 'is-primary'
                      : 'is-primary is-light'
                  "
                  >{{ search.status }}</b-tag
                >
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */ 
import { useSearchStore, useAuthStore } from "@/store"; // Adjust the path as necessary

export default {
  data() {
    return { recentSearches: [] }; // Initialize recentSearches as an empty array
  },
  created() {
    const searchStore = useSearchStore(); // Move searchStore initialization here
    const authStore = useAuthStore(); // Initialize authStore if needed
    
    this.searchStore = searchStore;
    this.authStore = authStore // Assign searchStore to this for access in mounted
  },

  async mounted() {
    // Check if the user is authenticated and if there are recent searches
    if (this.authStore.isAuthenticated) {
      this.recentSearches = await this.searchStore.viewAllSearchTerm(); // Access searchStore from this
      if (this.recentSearches.length > 0) {
        console.log(`Recent Search List`, this.recentSearches); //
      } else {
        console.log("No recent searches found.");
      }
    } else {
      console.log("User is not authenticated.");
    }
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
